import api from "./api"

export const loginService = (data) => {
  return api.post("/auth/login", data)
}

export const getDashboardService = () => {
  return api.get("/dashboard")
}

export const getDealsService = (page = 1) => {
  return api.get(`/deals?page=${page}`)
}

export const getMastersService = (page = 1) => {
  return api.get(`/master/index?page=${page}`)
}

export const createMasterService = (login) => {
  return api.get(`/master/create?login=${login}`)
}

export const deleteMasterService = (masterId) => {
  return api.get(`/master/destory?id=${masterId}`)
}

export const getSingleMasterView = (id) => {
  return api.get(`/master/view?login=${id}`)
}
export const getSlavesService = (page = 1, limit = 30, login = undefined) => {
  let query = `page=${page}`

  if (limit) {
    query += `&limit=${limit}`
  }

  if (login) {
    query += `&master=${login}`
  }

  return api.get(`/master/index?${query}`)
}

export const updateMasterService = (id, login, isActive) => {
  return api.get(`/master/update?id=${id}&login=${login}&isActive=${isActive}`)
}

export const createSlaveService = (login, master) => {
  return api.get(`/slave/create?login=${login}&master=${master}`)
}

export const updateSlaveService = (id, login, master, isActive) => {
  return api.get(
    `/slave/update?id=${id}&login=${login}&master=${master}&isActive=${isActive}`
  )
}

export const deleteSlaveService = (id) => {
  return api.get(`/slave/destory?id=${id}`)
}
